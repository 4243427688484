@import 'Style/colors';
.fc-gallery {

  display: block;

  &__header {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    font-style: italic;
    position: sticky;
    top: -1px;
    z-index: 10;
    min-height: 40px;
    pointer-events: none;
  }

  &__description {
    color: #808289;
    pointer-events: all;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 1.4;
  }

  &__header.isSticky {
    .case-gallery__description {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__indicator {
    display: flex;
  }

  &__controls {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: 20px;
  }

  &__prev,
  &__next {
    pointer-events: all;
    cursor: pointer;
  }

  &__slide {
    height: auto;
    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 1220px) {
    margin-top: -40px;
    &__header {
      z-index: 9;
      margin-left: -30px;
      margin-right: -30px;
      padding-left: 30px;
      padding-right: 30px;

      &:before {
        content: '';
        position: absolute;
        top: 0px;
        height: 100%;
        left: 0;
        right: 0;
        background: white;
        z-index: -1;
      }

      &.isSticky {
        box-shadow: 0px 0px 10px rgba(46, 47, 49, 0.2);
        padding-top: 40px;
        min-height: 80px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    margin: 24px 0;
    &__header {
      z-index: 11;
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;

      &:before {
      }

      &.isSticky {
        box-shadow: 0px 0px 10px rgba(46, 47, 49, 0.2);
        padding-top: 0px;
        min-height: 40px;
      }
    }

    .swiper-container,
    .case-image {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}
