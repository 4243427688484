@breakpointXl: 1220px;
@breakpointLg: 991px;
@breakpointSm: 767px;

.cases {
  .col-lg-4 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .case-view {
    position: relative;

    &__controls-wrapper {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      pointer-events: none;
    }

    &__controls {
      position: sticky;
      top: -1px;
      width: 100%;
      margin-top: 20px;
      display: flex;

      &-shadow {
        position: fixed;
        top: 0px;
        left: 0;
        right: 0;
        height: 40px;
        box-shadow: 0px 0px 10px rgba(46, 47, 49, 0.2);
        display: none;
        z-index: 9;
      }

      &.isSticky {
        z-index: 10;
        height: 40px;
        background: white;

        & + .case-view__controls-shadow {
          display: block;
        }
      }
    }

    &__control {
      position: absolute;
      color: inherit;
      text-decoration: none;
      top: 0px;
      display: flex;
      align-items: center;
      font-size: 13px;
      height: 40px;
      pointer-events: all;
      cursor: pointer;
      line-height: 1;

      &--previous {
        left: 0;
      }

      &--next {
        right: 0;
        text-align: right;
      }

      svg {
        flex-shrink: 0;
      }
    }

    &__header {
      margin-bottom: 30px;
      display: flex;
      align-items: center;

      @media screen and (max-width: 767px) {
        justify-content: center;
      }
    }

    &__logo {
      width: 104px;
      height: 78px;
      display: flex;
      padding: 10px;
      border: 2px solid #f4f6fe;
      border-radius: 4px;
      margin-right: 22px;
      flex-shrink: 0;

      @media screen and (max-width: 767px) {
        width: 100%;
        height: 180px;
      }

      img {
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }

    &__title {
      padding: 4px 0 5px;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;

      @media screen and (max-width: 767px) {
        align-items: center;
        height: auto;
        margin: 0 auto;
      }

      &__text {
        font-size: 40px;
        font-weight: 900;
        line-height: 1.1;

        @media screen and (max-width: 767px) {
          text-align: center;
          margin-top: 8px;
          font-size: 25px;
          line-height: 120%;
        }

        &__small {
          font-size: 20px;

          @media screen and (max-width: 767px) {
            font-size: 25px;
          }
        }
      }

      &__services {
        display: flex;
        align-items: flex-end;
        margin-top: 12px;

        @media screen and (max-width: 767px) {
          margin-top: 8px;
          margin-right: -10px;
          justify-content: center;
          flex-wrap: wrap;
        }

        &__item {
          cursor: pointer;
          margin: 0 14px 0 0 !important;
          font-family: Roboto;
          font-weight: 400;
          font-size: 15px;
          line-height: 70%;
          color: #808289;

          @media screen and (max-width: 767px) {
            justify-content: space-between;
            margin: 0 10px 8px 0 !important;
          }

          &__iiko:hover {
            fill: #ef322f;
          }

          &__focuz:hover {
            fill: #3f65f1;
          }

          &__sakura:hover {
            & path {
              fill: #333333;
            }
            & path:last-child {
              fill: #ff238d;
            }
          }

          &__text:hover {
            color: #000000;
          }
        }
      }
    }

    &__used {
      background: #f4f6fe;
      margin-top: 5px;
      border-radius: 9px;
      padding: 22px 30px;

      @media screen and (max-width: 767px) {
        margin-top: -8px;
        margin-left: -20px;
        width: calc(100% + 40px);
        border-radius: 0;
        padding: 22px 25px;
      }

      &__title {
        margin: 0 !important;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 140%;
        color: #000000;

        @media screen and (max-width: 767px) {
          font-size: 13px;
        }
      }

      ul {
        font-family: monospace;
        list-style: none;
        margin-top: 3px;
        font-family: Roboto;
        font-weight: 400;
        font-size: 15px;
        padding: 0 0 0 2px;

        @media screen and (max-width: 767px) {
          font-size: 13px;
        }

        li {
          margin: 0;
          padding-left: 16.5px;
          margin-bottom: 3px;
          position: relative;

          &:after {
            content: "";
            width: 6px;
            height: 6px;
            background: #b0c0ff;
            transform: rotate(-45deg);
            display: block;
            position: absolute;
            top: 8px;
            left: 0;
          }

          @media screen and (max-width: 767px) {
            &:after {
              top: 7px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &__content {
      max-width: 775px;
      margin: auto;
      font-size: 17px;
      line-height: 1.4;

      p {
        margin: 24px 0;
      }

      blockquote {
        margin: 50px 0;
        border-top: 3px solid #e2e8fd;
        border-bottom: 3px solid #e2e8fd;
        padding: 30px 65px;
        position: relative;
        font-style: italic;

        &:before,
        &:after {
          position: absolute;
          top: 0;
          bottom: 0;
          color: #8ca3f7;
          font-size: 80px;
          font-weight: 700;
          display: flex;
          align-items: center;
          line-height: 1;
          padding-bottom: 14px;
        }

        &:before {
          content: "\00ab";
          left: 0;
        }

        &:after {
          content: "\00bb";
          right: 0;
        }
      }
    }

    &__footer {
      font-size: 13px;
      margin-top: 40px;
      padding-top: 10px;

      .case-view__footer-controls {
        margin-bottom: 27px;
      }

      .case-view__footer-control {
        cursor: pointer;
        display: flex;
        align-items: center;
        line-height: 1;
        width: 50%;

        svg {
          flex-shrink: 0;
        }

        &:first-child {
          margin-right: 5px;
        }

        &:last-child:not(:first-child) {
          margin-left: 5px;
          text-align: right;
          justify-content: flex-end;
        }
      }
    }
  }

  .case-image {
    img {
      width: 100%;
      height: auto;
    }
  }

  .case-gallery {
    margin: 50px 0;

    &__header {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      font-style: italic;
      position: sticky;
      top: -1px;
      z-index: 10;
      min-height: 40px;
      pointer-events: none;
    }

    &__description {
      color: #808289;
      pointer-events: all;
      padding-top: 5px;
      padding-bottom: 5px;
      line-height: 1.4;
    }

    &__header.isSticky {
      .case-gallery__description {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__controls {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      margin-left: 20px;
    }

    &__prev,
    &__next {
      pointer-events: all;
      cursor: pointer;
    }

    &__slide {
      img {
        width: 100%;
      }
    }
  }

  .img-gallery {
    &__header {
      &:before {
        display: none;
      }
    }
  }

  .foquz-btn {
    margin-top: 50px;
    margin-bottom: 0 !important;
  }

  @media screen and (max-width: @breakpointXl) {
    .b-landing__header {
      padding-bottom: 35px;
    }

    .case-view {
      &__header {
        margin-bottom: 30px;
      }
      &__controls {
        margin-top: 0px;
      }
      &__control {
        max-width: 50%;

        span {
          max-width: unset;      
        }
      }
      &__content {
        margin-top: 60px;
        max-width: none;
        width: 100%;
      }

      &__footer {
        margin-top: 20px;
      }
    }

    .case-gallery,
    .img-gallery {
      margin-top: 10px;
      &__header {
        min-height: 80px;
        padding-top: 40px;
        background: white;
        z-index: 9;
        margin-left: -30px;
        margin-right: -30px;
        padding-left: 30px;
        padding-right: 30px;

        &:before {
          content: "";
          position: absolute;
          top: 0px;
          height: 100%;
          left: 0;
          right: 0;
          background: white;
          z-index: -1;
        }

        &.isSticky {
          box-shadow: 0px 0px 10px rgba(46, 47, 49, 0.2);
        }
      }
    }

    .foquz-btn {
      margin-top: 35px;
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: @breakpointSm) {
    .b-landing__header {
      padding-bottom: 25px;
    }

    .b-landing__footer {
      padding-top: 50px;
    }

    &-list {
      .case {
        margin-bottom: 20px;
      }
    }

    .case-view {
      &__controls {
        position: static;
      }
      .case-view__controls-shadow {
        opacity: 0;
        visibility: 0;
      }
      &__header {
        flex-wrap: wrap;
        margin-bottom: 24px;
      }

      &__title {
        font-size: 25px;
      }

      &__content {
        font-size: 13px;

        blockquote {
          margin: 24px 0;
          padding-left: 40px;
          padding-right: 40px;

          &:after,
          &:before {
            font-size: 40px;
            padding-bottom: 7px;
          }
        }
      }
    }

    .case-gallery,
    .img-gallery {
      margin: 24px 0;
      &__header {
        min-height: 40px;
        padding-top: 0;
        z-index: 11;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;

        &:before {
        }
      }

      .swiper-container,
      .case-image {
        margin-left: -20px;
        margin-right: -20px;
      }
    }

    .foquz-btn {
      width: 100%;
      margin-bottom: 25px;
    }
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }

  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.cases {
  .b-landing__header {
    @media screen and (max-width: 1200px) {
      padding-bottom: 25px;
    }
    @media screen and (max-width: 767.8px) {
      padding-bottom: 15px !important;
    }
  }
  .b-landing-footer {
    margin-top: 80px;

    @media screen and (max-width: 1200px) {
      margin-top: 70px;
    }

    @media screen and (max-width: 767.8px) {
      margin-top: 50px;
    }
  }

  .case-view__content {
    @media screen and (max-width: 1200px) {
      margin-top: 70px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 50px;
    }
  }
}

.case-view {
  padding-top: 55px;

  .fc-gallery {
    margin: 30px 0;

    @media screen and (max-width: 1200px) {
      margin-top: 30px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0px;
    }
  }

  &__header {
    @media screen and (max-width: 767px) {
      margin-bottom: 10px !important;
    }
  }

  &__controls-wrapper {
    margin-top: 55px;
  }

  @media screen and (max-width: 1200px) {
    padding-top: 0;
    &__controls-wrapper {
      margin-top: 0;
    }
  }

  &__control {
    span {
      max-width: 140px;
      text-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;         
    }
  }
}

.case-card {
  height: 100%;
  background-color: #3f65f1;
  border-radius: 9px;
  color: white;
  height: 700px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &--bubbles {
    background-image: url("/img/cases/bubbles.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &--circles {
    background-image: url("/img/cases/circles.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &--questions {
    background-image: url("/img/cases/questions.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__body {
    padding-left: 60px;
    padding-right: 60px;
    font-size: 24px;
    line-height: 1.2;

    &:first-child {
      padding-top: 55px;
    }

    p {
      margin: 20px 0 !important;

      &.mt-0 {
        margin-top: 0 !important;
      }
    }
  }

  &__title {
    font-size: 45px;
    font-weight: 900;
    margin-bottom: 10px;
    line-height: 1.1;

    &--light {
      font-weight: 300;
      b,
      strong {
        font-weight: 900;
      }
    }
  }

  &__subtitle {
    font-weight: 100;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-size: 24px;

    strong,
    b {
      font-weight: 700;
    }
  }

  &__img {
    overflow: hidden;
    flex-grow: 1;
    position: relative;

    img {
      max-width: 100%;
      width: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    &--bottom {
      img {
        max-height: 100%;
        top: auto;
        bottom: 0;
      }
    }

    &--note {
      padding-left: 60px;
      display: flex;
      .note {
        width: 220px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 60px;
        color: #b0c0ff;
        font-size: 17px;
        line-height: 1.2;

        &:before {
          content: "*";
          display: block;
        }
      }

      img {
        left: 260px;
        right: auto;
      }
    }
  }

  &__list {
    list-style: none;

    li {
      position: relative;
      padding-left: 28px;

      margin: 32px 0;

      &:before {
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        top: 7px;
        left: 0;
        background: #b0c0ff;
        transform: rotate(45deg);
      }

      .small {
        font-size: 17px;
        color: #b0c0ff;
      }
    }
  }

  .case-card__title + .case-card__list {
    margin-top: 35px;
  }

  &-using {
    margin-top: 50px;
    border-top: 3px solid#3553C1;
    padding-top: 40px;
    font-size: 36px;
    font-weight: 300;

    &__duration {
      font-size: 45px;
      font-weight: 900;
    }
  }

  &-rating {
    display: flex;
    margin-bottom: 36px;
    margin-top: 36px;

    &__point {
      width: 120px;
      flex-shrink: 0;
    }

    &__point-wrapper {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
    }

    &__icon {
      width: 80px;
      height: 80px;
      margin-bottom: 15px;
      background-size: 80px 80px;
      background-repeat: no-repeat;
      background-position: center;

      &[data-icon="good"] {
        background-image: url("data:image/svg+xml,%3Csvg width='84' height='84' viewBox='0 0 84 84' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55.2524 54.4827C53.5765 56.1834 51.5821 57.5374 49.383 58.4674C47.1838 59.3973 44.8231 59.885 42.4355 59.9026C40.0479 59.9202 37.6802 59.4674 35.4676 58.57C33.255 57.6725 31.2408 56.3481 29.5401 54.6722M82 42C82 64.0914 64.0914 82 42 82C19.9086 82 2 64.0914 2 42C2 19.9086 19.9086 2 42 2C64.0914 2 82 19.9086 82 42Z' stroke='white' stroke-width='4' stroke-linecap='round'/%3E%3Cpath d='M34.7275 32.9091C34.7275 35.9216 32.2854 38.3637 29.2729 38.3637C26.2604 38.3637 23.8184 35.9216 23.8184 32.9091C23.8184 29.8967 26.2604 27.4546 29.2729 27.4546C32.2854 27.4546 34.7275 29.8967 34.7275 32.9091Z' fill='white'/%3E%3Cpath d='M60.182 32.9091C60.182 35.9216 57.7399 38.3637 54.7275 38.3637C51.715 38.3637 49.2729 35.9216 49.2729 32.9091C49.2729 29.8967 51.715 27.4546 54.7275 27.4546C57.7399 27.4546 60.182 29.8967 60.182 32.9091Z' fill='white'/%3E%3C/svg%3E");
      }

      &[data-icon="perfect"] {
        background-image: url("data:image/svg+xml,%3Csvg width='84' height='84' viewBox='0 0 84 84' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M34.9935 33.1046C34.1556 32.2542 33.1584 31.5772 32.0588 31.1122C30.9593 30.6473 29.7789 30.4034 28.5851 30.3946C27.3913 30.3858 26.2074 30.6122 25.1011 31.061C23.9948 31.5097 22.9877 32.1719 22.1374 33.0098M61.8996 33.1046C61.0617 32.2542 60.0645 31.5772 58.9649 31.1122C57.8653 30.6473 56.6849 30.4034 55.4911 30.3946C54.2973 30.3858 53.1135 30.6122 52.0072 31.061C50.9009 31.5097 49.8938 32.1719 49.0434 33.0098M55.253 54.4827C53.5771 56.1834 51.5827 57.5374 49.3836 58.4674C47.1845 59.3973 44.8237 59.885 42.4361 59.9026C40.0485 59.9202 37.6808 59.4674 35.4682 58.57C33.2556 57.6725 31.2414 56.3481 29.5407 54.6722M82 42C82 64.0914 64.0914 82 42 82C19.9086 82 2 64.0914 2 42C2 19.9086 19.9086 2 42 2C64.0914 2 82 19.9086 82 42Z' stroke='white' stroke-width='4' stroke-linecap='round'/%3E%3C/svg%3E");
      }
    }

    &__label {
      white-space: nowrap;
    }

    &__text {
      flex-grow: 1;
    }

    &__title {
      font-size: 32px;
      font-weight: 900;
      margin-bottom: 6px;
      line-height: 1.1;
    }
  }

  .case-card__body.case-card__quotes {
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-quote {
    font-size: 45px;
    font-weight: 300;

    padding-left: 80px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 70px;
    }

    &:before {
      content: "—";
      position: absolute;
      left: 0;
      top: 0;
      width: 85px;
      text-align: right;
      line-height: 1em;
      padding-right: 15px;
    }

    &__title {
      font-size: 100px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    &--lg {
      &:before {
        font-size: 100px;
        font-weight: bold;
      }
    }
  }

  &--bg {
    position: relative;
    overflow: hidden;

    .case-card__img {
      position: absolute;
      top: 60px;
      left: 60px;
      right: 60px;
      bottom: 60px;
      margin-left: 60px;
      transform: translateX(50%);
      z-index: 1;

      img {
        bottom: 0;
      }
    }

    .case-card__body {
      position: relative;
      z-index: 2;
    }

    .case-card__title {
    }
  }

  &--ads {
    .case-card__list {
      font-size: 30px;

      li:before {
        top: 11px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &__body {
      padding-left: 45px;
      padding-right: 45px;
    }
    &-quote {
      line-height: 1.1;
      &__title {
        font-size: 80px;
        margin-bottom: 15px;
      }
    }
  }

  @media screen and (max-width: 767.8px) {
    height: 410px;
    border-radius: 0;

    &--ads {
      .case-card__list {
        font-size: 13px;

        li:before {
          top: 4px;
        }
      }
    }

    &--bubbles,
    &--circles {
      background-image: none;
    }

    &__body {
      font-size: 13px;

      padding-left: 20px;
      padding-right: 20px;

      &:first-child {
        padding-top: 26px;
      }

      p {
        margin-top: 13px !important;
        margin-bottom: 13px !important;
      }
    }

    &__title {
      font-size: 26px;
      margin-bottom: 6px;
    }

    &__subtitle {
      font-size: 16px;
      margin-bottom: 16px;
    }

    &__img {
      &--note {
        flex-direction: column;
        padding: 0;

        .note {
          width: 100%;
          flex-shrink: 0;
          font-size: 11px;
          padding-left: 20px;
          padding-right: 20px;
          margin-bottom: 8px;
          padding-bottom: 0;

          &:before {
            display: none;
          }
        }

        .img {
          flex-grow: 1;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }

        img {
          position: static;
          margin: 0;
        }
      }
    }

    &-quote {
      font-size: 22px;
      padding-left: 40px;

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      &:before {
        width: 40px;
      }

      &--lg {
        &:before {
          font-size: 40px;
        }
      }

      &__title {
        font-size: 40px;
        margin-bottom: 8px;
      }
    }

    &__list {
      li {
        margin: 12px 0;
        padding-left: 22px;

        &:before {
          width: 6px;
          height: 6px;
          top: 4px;
        }

        .small {
          font-size: inherit;
        }
      }
    }

    .case-card__title + .case-card__list {
      margin-top: 0;
    }

    &-using {
      margin-top: 24px;
      padding-top: 20px;
      border-top-width: 1px;
      font-size: 22px;

      &__duration {
        font-size: 26px;
      }
    }

    &-rating {
      margin-top: 20px;
      margin-bottom: 20px;
      &__point {
        width: 70px;
      }
      &__icon {
        width: 20px;
        height: 20px;
        background-size: 20px;
        margin-bottom: 8px;

        &[data-icon="good"] {
          background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.3131 14.1207C13.8941 14.5459 13.3955 14.8844 12.8457 15.1168C12.296 15.3493 11.7058 15.4713 11.1089 15.4757C10.512 15.4801 9.92004 15.3669 9.36689 15.1425C8.81375 14.9181 8.3102 14.587 7.88502 14.1681M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M9.18235 8.72741C9.18235 9.48052 8.57183 10.091 7.81871 10.091C7.0656 10.091 6.45508 9.48052 6.45508 8.72741C6.45508 7.97429 7.0656 7.36377 7.81871 7.36377C8.57183 7.36377 9.18235 7.97429 9.18235 8.72741Z' fill='white'/%3E%3Cpath d='M15.546 8.72741C15.546 9.48052 14.9355 10.091 14.1824 10.091C13.4292 10.091 12.8187 9.48052 12.8187 8.72741C12.8187 7.97429 13.4292 7.36377 14.1824 7.36377C14.9355 7.36377 15.546 7.97429 15.546 8.72741Z' fill='white'/%3E%3C/svg%3E");
        }
        &[data-icon="perfect"] {
          background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.24838 8.77615C9.0389 8.56356 8.7896 8.39431 8.51471 8.27806C8.23981 8.16182 7.94472 8.10085 7.64627 8.09865C7.34782 8.09645 7.05186 8.15306 6.77528 8.26524C6.49871 8.37742 6.24694 8.54297 6.03434 8.75245M15.9749 8.77615C15.7654 8.56356 15.5161 8.39431 15.2412 8.27806C14.9663 8.16182 14.6712 8.10085 14.3728 8.09865C14.0743 8.09645 13.7784 8.15306 13.5018 8.26524C13.2252 8.37742 12.9735 8.54297 12.7609 8.75245M14.3132 14.1207C13.8943 14.5459 13.3957 14.8844 12.8459 15.1168C12.2961 15.3493 11.7059 15.4713 11.109 15.4757C10.5121 15.4801 9.9202 15.3669 9.36705 15.1425C8.8139 14.9181 8.31036 14.587 7.88518 14.1681M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
        }
      }
      &__title {
        font-size: 16px;
      }
    }
  }
}

.case-card {
  &--dns,
  &--sw,
  &--chio,
  &--vn,
  &--kiabi,
  &--garage,
  &--vtb,
  &--akiana,
  &--rodnik {
    &[data-index="2"] {
      .case-card__img {
        margin-top: 25px;
      }
      @media screen and (max-width: 767.8px) {
        .case-card__img {
          margin-top: 0;
          img {
            left: 20px;
            right: 20px;
            max-width: calc(100% - 40px);
          }
        }
      }
    }
  }

  &--dns {
    @media screen and (max-width: 1200px) {
      &[data-index="6"] {
        .case-card-quote {
          &:first-child {
            margin-top: -30px;
          }
          &:not(:last-child) {
            margin-bottom: 65px;
          }
        }
      }
    }
    @media screen and (max-width: 767.8px) {
      &[data-index="6"] {
        .case-card-quote {
          &:first-child {
            margin-top: 0px;
          }
          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  &--sw {
    &[data-index="3"] {
      .case-card__img {
        margin-bottom: -1px;
        margin-top: -30px;
        img {
          left: 245px;
          bottom: -2px;
        }
      }

      @media screen and (max-width: 767.8px) {
        .case-card__img {
          margin-top: 0;
        }
      }
    }

    &[data-index="5"] {
      .case-card__list {
        max-width: 50%;
      }

      .case-card__title {
        margin-top: 16px;
      }

      @media screen and (max-width: 767.8px) {
        .case-card__title {
          font-size: 16px;
          margin-top: 0;
        }
        .case-card__list {
          max-width: none;
        }
        .case-card__img {
          position: relative;
          transform: none;
          margin-left: 0;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          img {
            bottom: auto;
            left: 20px;
            right: 20px;
            max-width: calc(100% - 40px);
          }
        }
      }
    }

    &[data-index="6"] {
      @media screen and (max-width: 767.8px) {
        .case-card__subtitle {
          margin-bottom: 10px;
        }
        .case-card-rating {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }

    &[data-index="7"] {
      @media screen and (max-width: 1200px) {
        .case-card-quote {
          &:first-child {
            margin-top: -40px;
          }
        }
      }
      @media screen and (max-width: 767.8px) {
        .case-card-quote {
          &:first-child {
            margin-top: 0px;
          }
        }
      }
    }
  }

  &--chio {
    &[data-index="2"] {
      p {
        margin-top: 16px !important;
      }
    }
    &[data-index="3"] {
      .case-card__img {
        img {
          max-width: 844px;
          height: 100%;
          top: auto;
          bottom: -3px;
          left: 60px;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      &[data-index="3"] {
        .case-card__img {
          img {
            left: 50px;
          }
        }
      }
      &[data-index="6"] {
        .case-card-quote {
          &:not(:last-child) {
            margin-bottom: 40px;
          }
        }
      }
    }

    @media screen and (max-width: 767.8px) {
      &[data-index="1"] {
        .case-card__body {
          p {
            margin-top: 8px !important;
          }
        }
      }
      &[data-index="3"] {
        .case-card__body {
          p {
            margin-top: 8px !important;
          }
        }
        .case-card__img {
          img {
            left: 20px;
          }
        }
      }
      &[data-index="6"] {
        .case-card-quote {
          &:first-child {
            margin-top: -50px;
          }
          &:not(:last-child) {
            margin-bottom: 24px;
          }
        }
      }
    }
  }

  &--vn {
    &[data-index="3"] {
      .case-card__img {
        margin-top: -10px;
        img {
          bottom: -5px;
          max-width: none;
          height: 100%;
          top: auto;
          left: 60px;
          margin: 0;
        }
      }
    }

    &[data-index="7"] {
      .case-card__list {
        font-size: 30px;

        li:before {
          top: 11px;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      &[data-index="3"] {
        .case-card__img {
          img {
            left: 50px;
          }
        }
      }

      &[data-index="6"] {
        .case-card-quote {
          &:first-child {
            margin-top: -40px;
          }
        }
      }

      &[data-index="7"] {
        .case-card__list {
          font-size: 13px;

          li:before {
            top: 4px;
          }
        }
      }
    }

    @media screen and (max-width: 767.8px) {
      &[data-index="3"] {
        .case-card__body {
          p {
            margin-top: 8px !important;
          }
        }
        .case-card__img {
          img {
            left: 20px;
          }
        }
      }
      &[data-index="6"] {
        .case-card-quote {
          &:first-child {
            margin-top: 0px;
          }
        }
      }
    }
  }

  &--kiabi {
    &[data-index="3"] {
      .case-card__img {
        img {
          left: 287px;
          bottom: -2px;
        }
      }
    }
    &[data-index="6"] {
      .case-card-quote:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    @media screen and (max-width: 1200px) {
      &[data-index="6"] {
        .case-card-quote__title {
          font-size: 100px;
        }
        .case-card-quote {
          margin-right: -10px;
        }
      }
      &[data-index="7"] {
        .case-card-quote {
          &:not(:last-child) {
            margin-bottom: 150px;
          }
        }
      }
    }

    @media screen and (max-width: 767.8px) {
      &[data-index="2"] {
        .case-card__subtitle {
          margin-bottom: 18px;
        }
        .case-card__title {
          margin-bottom: 8px;
        }
        .case-card__img {
          margin-top: 10px;
        }
      }

      &[data-index="6"] {
        .case-card-quote__title {
          font-size: 40px;
        }
      }

      &[data-index="7"] {
        .case-card-quote {
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  &--garage {
    &[data-index="3"] {
      .case-card__img {
        img {
          left: 290px;
          bottom: -1px;
        }
      }
    }

    &[data-index="6"] {
      background-image: url("/img/cases/circles.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      p {
        font-size: 24px;
      }

      .case-card-quote:not(:last-child) {
        margin-bottom: 25px;
      }
    }

    @media screen and (max-width: 1200px) {
      &[data-index="7"] {
        .case-card-quote {
          &:first-child {
            margin-top: -30px;
          }
          &:not(:last-child) {
            margin-bottom: 150px;
          }
        }
      }
    }

    @media screen and (max-width: 767.8px) {
      &[data-index="6"] {
        background-image: none;

        p {
          font-size: 13px;
        }
      }

      &[data-index="2"] {
        .case-card__img {
          margin-top: 10px;
        }
      }

      &[data-index="7"] {
        .case-card-quote {
          &:first-child {
            margin-top: 0px;
          }
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  &--vtb {
    &[data-index="3"] {
      .case-card__img {
        .img {
          img {
            bottom: -2px;
            left: 287px;
          }
        }
      }
    }

    &[data-index="5"] {
      p {
        font-size: 24px;
        padding-right: 10px;
      }
      .case-card-quote {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        &:last-child {
          margin-bottom: 10px;
        }
      }

      @media screen and (max-width: 767.8px) {
        p {
          font-size: 13px;
          padding-right: 10px;
        }
        .case-card-quote {
          &:not(:last-child) {
            margin-bottom: 20px;
          }
          &:last-child {
            margin-bottom: 10px;
          }
        }
      }
    }

    &[data-index="6"] {
      .case-card-quote:first-child {
        margin-bottom: 30px;
      }
      .case-card-quote--lg {
        font-size: 24px;
        margin-bottom: 10px;
      }
    }
  }

  &--akiana {
    &[data-index="3"] {
      .case-card__img {
        .img {
          img {
            bottom: -2px;
            left: 270px;
          }
        }
      }
    }

    &[data-index="6"] {
      .case-card-quote {
        &:first-child {
          margin-bottom: 30px;
        }
        &--lg {
          &:before {
            font-size: 45px;
          }
          .case-card-quote__title {
            font-size: 45px;
          }
          p {
            font-size: 24px;
          }
          .footnote {
            font-size: 17px;
            color: #becbff;
            margin-top: 40px;
            max-width: 460px;

            position: relative;

            &:before {
              content: "*";
              position: absolute;
              left: 0;
              margin-left: -15px;
              top: 0;
            }
          }
        }
      }

      @media screen and (max-width: 767.8px) {
        .case-card-quote {
          &:first-child {
            margin-bottom: 30px;
          }
          &--lg {
            margin-bottom: 10px;
            p {
              font-size: 13px;
            }
            &:before {
              font-size: 26px;
            }
            .case-card-quote__title {
              font-size: 26px;
            }
          }

          .footnote {
            margin-left: -40px;
            font-size: 11px;
            &:before {
              display: none;
            }
          }
        }
      }
    }

    &[data-index="7"] {
      .case-card-quote {
        &--lg {
          &:before {
            font-size: 80px;
          }
        }
        &__title {
          font-size: 80px;
        }
      }

      @media screen and (max-width: 767.8px) {
        .case-card-quote {
          &:first-child {
            margin-bottom: 30px;
          }
          &--lg {
            margin-bottom: 10px;
            p {
              font-size: 13px;
            }
            &:before {
              font-size: 40px;
            }
          }
          &__title {
            font-size: 40px;
          }
        }
      }
    }
  }

  &--rodnik {
    &[data-index="3"] {
      .case-card__img {
        .img {
          img {
            bottom: -3px;
          }
        }
      }
    }
    &[data-index="6"] {
      .case-card-quote {
        &:first-child {
          margin-bottom: 30px;
        }
        &--lg {
          margin-bottom: 10px;
          p {
            font-size: 24px;
          }
          &:before {
            font-size: 80px;
          }
        }
        &__title {
          font-size: 80px;
        }
      }

      @media screen and (max-width: 767.8px) {
        .case-card-quote {
          &:first-child {
            margin-bottom: 30px;
          }
          &--lg {
            margin-bottom: 10px;
            p {
              font-size: 13px;
            }
            &:before {
              font-size: 40px;
            }
          }
          &__title {
            font-size: 40px;
          }
        }
      }
    }
    &[data-index="7"] {
      .case-card-quote {
        &:first-child {
          margin-bottom: 130px;
        }
        &--lg {
          margin-bottom: 10px;

          &:before {
            font-size: 80px;
          }
        }
        &__title {
          font-size: 80px;
        }
      }

      @media screen and (max-width: 767.8px) {
        .case-card-quote {
          &:first-child {
            margin-bottom: 30px;
          }
          &--lg {
            margin-bottom: 10px;
            p {
              font-size: 13px;
            }
            &:before {
              font-size: 40px;
            }
          }
          &__title {
            font-size: 40px;
          }
        }
      }
    }
  }
}

.case-card__summary-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 42px;

  @media screen and (max-width: 767.8px) {
    display: flex;
    flex-direction: column;
  }

  .summary-list__item {
    margin-bottom: 15px;

    &:first-child {
      grid-row: span 2;
    }
  }
  .summary-list__item-icon {
    margin-bottom: 22px;
  }
  .summary-list__item-text {
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    line-height: 140%;
    margin: 0;

    @media screen and (max-width: 767.8px) {
      font-size: 13px;
      line-height: 120%;
    }
  }
}

.case-card {
  &--sibur {
    &[data-index="2"] {
      .case-card__title {
        margin-bottom: 50px;
        @media screen and (max-width: 767.8px) {
          font-size: 22px;
          font-weight: 900;
          line-height: 110%;
          margin-bottom: 20px;
        }
      }
    }
    .summary-list__item-icon {
      @media screen and (max-width: 767.8px) {
        margin-bottom: 10px;

        svg {
          height: 20px;
          width: auto;
        }
      }
    }

    &[data-index="3"] {
      .case-card__img {
        max-width: 513px;
        align-self: center;
        overflow: unset;
        flex-grow: unset;
        position: unset;

        @media screen and (max-width: 767.8px) {
          max-width: 87%;
        }

        img {
          position: static;
        }
      }
    }

    &[data-index="5"] {
      .case-card__answer {
        font-size: 24px;
        line-height: 130%;
        display: flex;

        @media screen and (max-width: 767.8px) {
          font-size: 13px;
        }

        &::before {
          content: "—";
          margin-right: 14px;
        }
      }

      .case-card__subtitle {
        margin-bottom: 25px;

        @media screen and (max-width: 767.8px) {
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 300;
          line-height: 140%;
        }

        br {
          @media screen and (max-width: 767.8px) {
            display: none;
          }
        }
      }

      .case-card__img img {
        right: -72px;
        left: unset;
        height: 320px;

        @media screen and (max-width: @breakpointXl) {
          right: -139px;
        }

        @media screen and (max-width: 767.8px) {
          max-width: 81.63%;
          height: auto;
        }
      }

      .case-card__img .note {
        @media screen and (max-width: 767.8px) {
          margin-bottom: 0;
        }
      }
      .case-card__body {
        @media screen and (max-width: 767.8px) {
          margin-bottom: 10px;
        }
      }
    }
  }
  
  &--mriya,&--yamed {
    &[data-index="4"] {
      .case-card-quote {
        margin-bottom: 30px;
        &:nth-child(2) {
          margin-bottom: 46px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &[data-index="3"] {
      .case-card__title {
        margin-bottom: 20px;
      }
      .case-card__description {
        margin-bottom: 35px;
      }
      .case-card-using {
        margin-top: 0;
      }
    }
    &[data-index="6"] {
      .case-card-quote--lg {
        font-size: 24px;
      }
    }
  }

  &--liga-stavok&[data-index="3"],
  &--detskii-sad&[data-index="4"],
  &--askona&[data-index="3"],
  &--skillfactory&[data-index="3"] {
    .case-card__title {
      @media screen and (max-width: 767.8px) {
        font-size: 24px;
        font-weight: 900;
        line-height: 110%;
        margin-bottom: 10px;
      }
    }

    .case-card__description {
      margin-bottom: 35px;

      @media screen and (max-width: 767.8px) {
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 20px;
      }
    }

    .case-card-using {
      margin-top: 0;
    }
  }

  &--detskii-sad&[data-index="5"],
  &--askona&[data-index="4"],
  &--skillfactory&[data-index="6"] {
    .case-card-quote {
      @media screen and (max-width: 767.8px) {
        margin-bottom: 10px;
      }

      &:nth-child(2n) {
        @media screen and (max-width: 767.8px) {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.case-card__img-double {
  display: flex;
  justify-content: center;

  img {
    width: 260px;

    @media screen and (max-width: 767.8px) {
      width: 144px;

      &:first-child {
        margin-right: -9px;
      }
    }

    &:first-child {
      margin-right: -16px;
      z-index: 1;
    }
  }
}

.case-card-quote__tail {
  font-size: 24px;
  line-height: 120%;

  @media screen and (max-width: 767.8px) {
    font-size: 13px;
    line-height: 120%;
  }
}
